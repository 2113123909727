import './App.css'
import Navigator from './components/header/Navigator'
import Banner from './components/banner/Banner'
import VideoUpload from './components/dnd/VideoUpload'
import { useState } from 'react'
import BreadCrump from './components/breadcrumb/BreadCrump'

function App() {
  const [type, setType] = useState('File Converter')

  return (
    <>
      <Navigator />
      <div className="App">
        <div className="ad-left"></div>
        <div className="vc-tool">
        <BreadCrump/>
          <VideoUpload />
        </div>
        <div className="ad-right"></div>
      </div>
    </>
  )
}

export default App
