import React, { useState } from 'react'
import Banner from '../banner/Banner'
import VideoUpload from '../dnd/VideoUpload'
import Navigator from '../header/Navigator'
import BreadCrump from '../breadcrumb/BreadCrump'

const MP4Converter = () => {
    const [type,setType]=useState("MP4 Converter")
  return (
    <>
      <Navigator />
      <div className="App">
        <div className="ad-left"></div>
        <div className="vc-tool">
            <BreadCrump  type={type}/>
          <Banner type={type} />
          <VideoUpload />
        </div>
        <div className="ad-right"></div>
      </div>
    </>
  )
}

export default MP4Converter
