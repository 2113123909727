import React, { useEffect } from 'react'
import './banner.css'

const Banner = () => {
  useEffect(()=>{


  },[])

  return (
    <div className="jwt-banner">
      <div className="jwt-container">

        <h1 className='banner-text'>JSON Web Tokens are an open, industry standard  <span style={{color:'blue'}}>RFC 7519</span> method for representing claims securely between two parties.</h1>
        <p>
          Convert your videos to MP4, MOV, or AVI without losing quality. Our easy-to-use video converter lets you
          convert videos online for free. No sign-ups, no
          watermarks—just fast and reliable conversions. 
        </p>
      </div>
    </div>
  )
}

export default Banner
