import React from 'react'
import "./breadcrumb.css"
import { useNavigate } from 'react-router-dom'

const BreadCrump = (props) => {
    const navigate = useNavigate()

    const handleNavigate = ()=>{
        navigate("/")
    }
  return (
    <div className='bc-c' >
      <ul className='bc'>
        <li onClick={()=>{handleNavigate()}}>Home {props.type ? "/" :''} </li>
        <li className='active'>{props.type}</li>
      </ul>
    </div>
  )
}

export default BreadCrump
